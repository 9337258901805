import './Header.css';

import React from 'react';
import { compose } from 'recompose';
import { DesktopLarge, FlexBox, Mobile, Tablet, HeaderMenu } from 'travelopod-components';

import Logo from './Logo';
import CallUs from './CallUs';
import {
  MARKETING_URL_BLOG,
  MARKETING_URL_CARS,
  MARKETING_URL_CORPORATE_TRAVEL,
  MARKETING_URL_FLIGHTS,
  MARKETING_URL_GROUP_TRAVEL,
  MARKETING_URL_HOTELS,
  MARKETING_URL_TRIP_PLANNER,
  MARKETING_URL_VACATION,
} from '../../constants/global';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';
import FixedWidthContainer from '../FixedWidthContainer/FixedWidthContainer';
// import LoginLogoutBtn from '../LoginLogoutBtn';

const generateLinks = (buildMarketingUrl) => ({
  flightsLink: buildMarketingUrl(MARKETING_URL_FLIGHTS),
  corporateTravelLink: buildMarketingUrl(MARKETING_URL_CORPORATE_TRAVEL),
  groupTravelLink: buildMarketingUrl(MARKETING_URL_GROUP_TRAVEL),
  hotelsLink: buildMarketingUrl(MARKETING_URL_HOTELS),
  carsLink: buildMarketingUrl(MARKETING_URL_CARS),
  tripPlannerLink: buildMarketingUrl(MARKETING_URL_TRIP_PLANNER),
  blogLink: buildMarketingUrl(MARKETING_URL_BLOG),
  vacationsLink: buildMarketingUrl(MARKETING_URL_VACATION),
});

const Header = ({ buildMarketingUrl }) => (
  <div className="header-wrapper">
    <FixedWidthContainer className="header">
      <DesktopLarge>
        <Logo />
        <HeaderMenu className="header-menu-component" links={generateLinks(buildMarketingUrl)} />
      </DesktopLarge>

      <Tablet>
        <FlexBox alignItems="center">
          <HeaderMenu className="header-menu-component" links={generateLinks(buildMarketingUrl)} />
          <Logo />
        </FlexBox>
      </Tablet>

      <Mobile>
        <FlexBox alignItems="center">
          <HeaderMenu className="header-menu-component" links={generateLinks(buildMarketingUrl)} />
          <Logo />
        </FlexBox>
      </Mobile>

      <CallUs />
    </FixedWidthContainer>
    {/* <div>
      <LoginLogoutBtn />
    </div> */}
  </div>
);

export default compose(withCallTrackingUrl())(Header);
