import React from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';
import { connect as reduxConnect } from 'react-redux';

import withDomain from '../../Hocs/withDomain';
import { formatPhone } from '../../helpers/phone';
import { markAsOpened } from '../../helpers/exitIntent';
import { triggerMetaPixelCallNow } from '../../helpers/metaPixelEvents';
import { getPhoneDataSelector } from '../../store/modules/responseTap/phoneData';

class ResponseTapPhone extends React.PureComponent {
  componentDidUpdate(prevProps, prevState) {
    if (!this.rTapNumberRef) {
      return;
    }

    if (prevProps.phoneData === this.props.phoneData) {
      return;
    }

    const { phone } = this.props.phoneData;

    this.href.setAttribute('href', formatPhone(phone, 'RFC3966'));
    this.href.innerHTML = phone;
  }

  rTapRef = (node) => {
    if (!node) {
      return this.interval && clearInterval(this.interval);
    }

    const { className, phoneData, location } = this.props;
    let href;
    const url = new URL(window.location.href);
    let phonenum =
      url.searchParams.get('phoneNumber') ||
      (location && location.state && location.state.queryFilters && location.state.queryFilters.phoneNumber);

    // anchor element
    const a = document.createElement('a');
    if (phonenum) {
      href = formatPhone(phonenum, 'RFC3966');
      a.innerHTML = formatPhone(phonenum, 'INTERNATIONAL');
    } else {
      href = formatPhone(phoneData.phone, 'RFC3966');
      a.innerHTML = phoneData.phone;
    }
    // set anchor element id - used for identification on GA
    a.setAttribute('id', this.props.callUsEventId);

    // set anchor element classNames - for styling
    const linkClassName = classNames(className, href, 'underline');
    a.classList.add(...linkClassName.split(' '));

    // set anchor element href
    a.setAttribute('href', href);

    // set anchor event onClick - event trackings
    a.onclick = () => {
      // Meta-PIXEL Call Now event trigger
      triggerMetaPixelCallNow();
    };

    node.appendChild(a);

    this.href = a;
    this.rTapNumberRef = node;
  };

  onClick() {
    markAsOpened();
  }

  render() {
    return (
      <span
        ref={this.rTapRef}
        onClick={() => {
          this.onClick();
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  phoneData: getPhoneDataSelector(state),
});

export default compose(withDomain(), reduxConnect(mapStateToProps))(ResponseTapPhone);
